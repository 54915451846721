
:root { 
    --theme-background:#F47240; 
    --button-primary-hover:#f36229;    
    --button-secondary:#3C2E3C;  
    --button-secondary-hover:#302430;
    --button-danger:#FF8A8A;          
    --button-danger-hover:#fd7171; 
    --button-warning:#d39e00;  
    --button-warning-hover:#e0a800;
    --button-success:#449d44;  
    --button-success-hover:#0f863d;

    --button-disabled:#f5f5f5; 
    --border-disabled:#dcdcdc;  

    // Global Primary Text color css
        --primary-text-color:#3C2E3C;
        --secondary-text-color:#635863;
        --dark-text-color:#373737;
        --orange-text-color:#F47240;

    

       // Global panel box variables css
       --panel-default:#f5f5f5;
       --panel-primary:#3C2E3C;
       --panel-body-boder:#d5d5d5;

       // Global input variables css
       --input-border:#ddd;
       --placeholder-color:#b8b8b8;
       --input-color:#8a8b8c; 

       --active-color:#64c880;
       --active-color-hover:#51be70;
       --inactive-color:#ff8a8a;
       --pending-color:#ffa958;
       --order-color:#61A4E4;
        // Global table ode & even variables css
        
       
 }
// Global variables css 
$border-radius:3px;
$font-bold:700;
$globle-large: 1.1em;
$globle-text: 0.76rem;
$globle-text-second: 0.87rem;


// Global variables taxt color css 
$color-white: #FFFFFF;
$color-black: #000000;
$color-alert: #ff0000;
$color-light-grey: #f6f6f6;
$color-grey: #e2e2ea;
$color-dark-light:#ddd;
$color-dark-grey : #cfdbe2;
$color-primary : #3C2E3C;
$color-secondary : #635863;
$color-blow:#0076BC;


