/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
@import "./variable.scss";
@import "~angular-notifier/styles";
@import "./app/shared/loader/loader.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #ffffff;
}

/*======= Style the customize scrollbar bar ========*/
::-webkit-scrollbar {
  width: 5px;
  background: #eee;
  width: 5px;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb {
  background: var(--theme-background);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--theme-background);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--theme-background);
}
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
body::-webkit-scrollbar-thumb {
  background: var(--theme-background);
  outline: 1px solid rgba(239, 246, 253, 0.75);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: var(--theme-background);
}

// ------Style the Custom checkbox
.checkbox-input {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    padding: 0;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    .user-terms-text {
      font-size: $globle-text-second;
      font-weight: 600;
    }
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 6px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $border-radius;
  }

  // Box hover
  &:hover + label:before {
    background: transparent;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.034);
  }

  // Box checked
  &:checked + label:before {
    background: var(--theme-background);
    border: 1px solid transparent;
  }

  // Disabled state label.
  &:disabled + label {
    color: var(--placeholder-color);
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: $color-dark-light;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 8px;
    background: $color-white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 $color-white, 4px 0 0 $color-white,
      4px -2px 0 $color-white, 4px -4px 0 $color-white, 4px -6px 0 $color-white,
      4px -8px 0 $color-white;
    transform: rotate(45deg);
  }
  &:checked + label.manage-checkbox:after {
    left: 3px;
    top: 9px;
  }
}

.form-group .input-element:focus,
.form-group .input-element:hover {
  border: 1px solid var(--theme-background);
}
.form-group .input-element.is-invalid {
  border: 1px solid $color-alert;
}

.form-control-label .checkbox-label-text {
  .label-text {
    margin-bottom: 1em;
  }
}

// ------ End Custom checkbox styles

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid $color-dark-light;
  border-radius: 100%;
  background: $color-white;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: var(--theme-background);
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
// ------ End Custom radio styles

.auth {
  display: table;
  height: 100%;
  padding: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  &-right-contant {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100vh;
    position: fixed;
    width: 59%;
    right: 0;
    top: 0;
    bottom: 0;
    .auth-contant {
      background: #fff;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      &-panel {
        margin: 13% auto;
        clear: both;
        width: 90%;
        max-width: 515px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
      }
      &-heading,
      .sub-heading {
        font-size: 1.8rem;
        font-weight: 600;
        font-family: "Roboto", sans-serif;
        color: #3c2e3c;
      }
      &-heading {
        span {
          color: var(--orange-text-color);
        }
      }
      .sub-heading {
        font-size: 0.9rem;
        font-weight: 400;
        color: #8083a3;
        opacity: 0.8;
      }
    }
  }
}

.auth-main-contant {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  width: 77%;
  margin: 10% auto;
}
.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}

.forgot-text,
.sign-up-text {
  text-align: right;
  text-decoration-line: underline;
  font-size: 0.87rem;
  font-weight: 600;
  color: var(--orange-text-color);
  &:hover,
  &:focus {
    text-decoration-line: none;
  }
}
.forgot-text {
  &:hover,
  &:focus {
    color: var(--orange-text-color);
  }
}
.sign-up-text {
  color: #9598b2;
  text-decoration-line: none;
  a {
    color: var(--orange-text-color);
    text-decoration-line: none;
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration-line: underline;
    }
  }
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--orange-text-color);
}

.button {
  min-height: 44px;
  font-weight: 600;
}

// End of the Auth style css

.form-group {
  &-label {
    font-size: 0.8rem;
  }
}

.input-element {
  outline: none;
  box-shadow: none;
  color: var(--input-color);
  flex: auto;
  height: 42px;
  background: $color-light-grey;
  border: 1px solid var(--input-border);
  font-size: $globle-text-second;
  border-radius: $border-radius;
  max-width: 100%;
  position: relative;
  padding: 8px 8px;
  width: 100%;
  &:hover,
  &:focus {
    border: 1px solid var(--input-border);
    box-shadow: none;
    outline: none;
  }
  &::placeholder {
    color: var(--placeholder-color);
    font-size: $globle-text-second;
  }
}

.mat-input-form {
  width: 100%;
  .mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 0.5em solid transparent;
    .mat-form-field-label {
      font-size: 0.9rem;
      top: 1.4em;
      font-weight: 500;
    }
    .mat-input-element {
      font-size: 0.9rem;
      font-weight: 500;
      background: transparent !important;
      color:#3c2e3c;
    }
  }
  .mat-form-field-underline {
    display: none;
  }
  &.mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px 4px 4px 4px;
    padding: 0.75em 0.75em 0 3em;
  }

  &.inner-input {
    &.mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius: 4px 4px 4px 4px;
      padding: 0.75em 0.75em 0 0.75em;
    }
  }
  &.mat-field{
    .mat-form-field-wrapper{
           margin-bottom: 0px;
           padding-bottom: 0px;
    }
    &.mat-form-field-appearance-fill .mat-form-field-flex {
      border-radius:0px;
      padding: 0.75em 0.75em 0  0.75em;
    }
  }
}
.text-alart {
  color: var(--orange-text-color);
}

.mat-form-group {
  position: relative;
  .login-icon {
    position: absolute;
    top: 0px;
    left: -35px;
  }
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-0.89375em) scale(0.75);
}
.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper {
  padding: 0rem !important;
}
// End Mat and input style css

// ------Style the Button
.button {
  min-height: 36px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  padding: 8px 10px;
  color: $color-white;
  border-radius: $border-radius;
  border-color: transparent;
  transition: all 0.4s ease-in-out 0s;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: $globle-text-second;
  font-weight: 600;
  box-shadow: none;
  outline: none;

  &:hover,
  &:focus {
    color: $color-white;
    text-decoration: none;
    box-shadow: none;
    outline: none;
  }
  &.button-primary {
    background: var(--theme-background);
    &:hover,
    &:focus {
      background: var(--button-primary-hover);
      transition: all 0.2s ease-in-out 0s;
      outline: none;
    }

    &-light{
      background: rgba(244, 114, 64, 0.15);
      border-radius: 0px 0px 10px 10px;
      height: 46px;
      color: var(--orange-text-color);
      &:hover,
    &:focus {
      color:  var(--orange-text-color);
      height: 46px;
      background: rgba(244, 114, 64, 0.15);
      border-radius: 0px 0px 10px 10px;
      transition: all 0.2s ease-in-out 0s;
      outline: none;
    }
    }
  }
  &.button-secondary {
    background: var(--button-secondary);
    box-shadow: none;
    outline: none;
    color:#fff;
    &:hover,
    &:focus {
      background: var(--button-secondary-hover);
      transition: all 0.2s ease-in-out 0s;
      outline: none;
    }
  }
  &.button-danger {
    background: var(--button-danger);
    &:hover,
    &:focus {
      background: var(--button-danger-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-success {
    background: var(--button-success);
    &:hover,
    &:focus {
      background: var(--button-success-hover);
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-default {
    background: transparent;
    border: 1px solid #dcdcdc;
    color: var(--secondary-text-color);
    // padding: 8px 8px;
    &:hover,
    &:focus {
      background-color: #f5f5f5;
      transition: all 0.2s ease-in-out 0s;
    }
  }
  &.button-upload {
    background: transparent;
    border: 1px solid  var(--orange-text-color);
    color: var(--orange-text-color);
    // padding: 8px 8px;
    &:hover,
    &:focus {
      background-color: transparent;
      transition: all 0.2s ease-in-out 0s;
    }
  }

  &.disabled {
    background: var(--button-disabled);
    border: 1px solid var(--border-disabled);
    color: var(--secondary-text-color);
    &:hover,
    &:focus {
      background: var(--button-disabled);
      transition: all 0.2s ease-in-out 0s;
    }
  }

  &.btn-group {
    background-color: #fbfbfb;
    border: 1px solid #ced4da;
    em.fa {
      color: #333;
      font-size: $globle-text;
    }
    &:hover,
    &:focus {
      background-color: #f5f5f5;
      outline: none;
      box-shadow: none;
    }
  }
 &.remove-button{
        background: $color-white;
        border: 1px solid var(--border-disabled);
        color: var(--secondary-text-color);
        box-shadow: none;
        outline: none;
        &:hover,
        &:focus {
          background: var(--button-disabled);
          transition: all 0.2s ease-in-out 0s;
          box-shadow: none;
          outline: none;
        }
 }


}
.strong-text {
  font-weight: $font-bold;
}

// breadcrumb style
.breadcrumb {
  padding: 0.3rem 1rem;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  &.breadcrumb-row {
    align-items: center;
  }
}
// card style
.card {
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  &.card-container {
    border-radius: 0.25rem;
  }
  .card-body-content {
    transition: all 0.2s;
    &:hover,
    &:focus {
      box-shadow: 0 0 11px 0 hsl(215deg 9% 64% / 22%);
      transition: all 0.4s;
    }

    &.card-body-border{
      background: #FFFFFF;
      border: 1px solid #E2E2EA;
      box-sizing: border-box;
      border-radius: 10px;
      &:hover,
      &:focus {
        box-shadow: none;
        transition: all 0.4s;
      }
    }
  }


}
.hedding,
.subhedding {
  color: $color-primary;
  font-size: 1.2rem;
  font-weight: 700;
}
.subhedding {
  font-size: 1rem;
}
.link {
  font-size: 0.86rem;
  font-weight: 600;
  color: #0076bc;
}

.text {
  color: $color-secondary;
  line-height: 24px;
  font-weight: 600;
}
// List style css

.profile-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .filter {
    &-icon {
      display: flex;
      &-cell {
        background: $color-white;
        border: 1px solid #e2e2ea;
        border-radius: 4px;
        padding: 10px;
        &:hover,
        &:focus {
          background: #f1f1f5;
          border: 1px solid #e2e2ea;
        }
      }
    }
  }
}
.details-vendor {
  border-top: none;
  overflow-y: auto;
}
.categories-list-view {
  max-height: 100%;
  min-height: auto;
}
.online {
  border-bottom: 1px solid #d5d5dc;
  min-height: 80px;
  &:hover,
  &.active {
    background: rgba(97, 164, 228, 0.2);
  }
  &.detail-topbar {
    min-height: 75px;
    border-bottom: 0px;
    &:hover {
      background: $color-white;
    }
  }
}

.theme {
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
  width: 50px;
  height: 45px;
  border: 1px solid #d5d5dc;
  border-radius: 4px;
  overflow: hidden;
  font-size: 0.89rem;
  font-weight: 700;
  &-color-1 {
    background: rgba(0, 118, 188, 0.15);
    border: 1px solid rgba(0, 118, 188, 0.15);
    color: #0076bc;
  }
  &-color-2 {
    background: rgba(255, 169, 88, 0.2);
    border: 1px solid rgba(255, 169, 88, 0.2);
    color: #ffa958;
  }
  &-color-3 {
    background: rgba(0, 188, 216, 0.15);
    border: 1px solid rgba(0, 188, 216, 0.15);
    color: #00bcd8;
  }
}

// Starts Account Managment Styles CSS

.account {
  &-empty {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 96vh;
    &-text {
      color: $color-secondary;
      opacity: 0.8;
      line-height: 24px;
      font-weight: 400;
      width: 100%;
    }
  }
}

.modale-header {
  &.account-section {
    background: white;
    padding: 0px;
    z-index: 10000;
    width: 100%;
    height: 100vh;
  }
  &-title {
    background-color: $color-white;
    &-hedding {
      font-size: 1rem;
    }
    &-link {
      text-decoration: none;
      font-size: 0.89rem;
      font-weight: 700;
      &:hover,
      &:focus {
        text-decoration: none;
        box-shadow: none;
        outline: none;
        color: #0076bc;
      }
    }
  }
  &-empty {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    &-hedding {
      font-size: 1.5rem;
    }
    &-text {
      color: $color-secondary;
      opacity: 0.8;
      line-height: 24px;
      font-weight: 400;
      width: 65%;
    }
  }
  &.title-bar {
    border-bottom: 1px solid #d5d5dc;
  }
}

.text-remove-pic {
  color: var(--secondary-text-color);
  font-size: $globle-text-second;
  font-weight: 600;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--secondary-text-color);
    font-size: $globle-text-second;
  }
}

.content-dialog {
  min-height: 86vh;
}
.modale-body {
  padding: 0.3rem 1rem 1rem;
  max-height: calc(100vh - 10em);
  overflow-y: auto;
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.dialog-footer {
  border-top: 1px solid #d5d5dc;
}
// Search input style CSS
.search-input {
  background-image: url(./assets/images/web-icons/search.svg);
  background-position: 6px center;
  background-repeat: no-repeat;
  border: 1px solid $color-grey;
  border-radius: 4px;
  font-size: 14px;
  min-height: 41px;
  padding-left: 2.3rem;
  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.mat-icon-button.mat-dropdown-menu {
  width: 22px;
  height: 22px;
  line-height: 0;
  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
}
.mat-form-field-appearance-fill .mat-form-field-flex{
  background-color: rgb(255 255 255 / 0%);
    border: 1px solid #D5D5DC;
}
.mat-menu-panel {
  min-height: 50px !important;
  &.list-submenu {
    .mat-menu-item {
      line-height: 38px;
      height: auto;
      color: var(--primary-text-color);
      font-size: $globle-text-second;
      font-weight: 500;
      position: relative;
      &:hover,
      &:focus {
        box-shadow: none;
        outline: none;
        border: none;
        &::after {
          background-image: url(./assets/images/web-icons/line.svg);
          background-position: right center;
          background-repeat: no-repeat;
          content: "";
          position: absolute;
          right: 6px;
          top: 8px;
          width: 20px;
          height: 20px;
        }
      }

      .mat-icon {
        font-size: 17px;
        height: auto;
        width: auto;
      }

      &.delete {
        color: #ff8a8a;
        font-size: 1.01rem;
        .delete-icon {
          color: #ff8a8a;
          font-size: 20px;
        }
        &:hover {
          background: rgba(255, 138, 138, 0.2);
        }
      }
    }
    &.table-status {
      min-width: 165px;
      max-width: 165px;
    }
    &.submenu-status {
      min-width: 180px;
    }
    &.submenu-action {
      min-width: 165px;
    }
  }
}
.mat-dialog-container {
  border-radius: 6px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  overflow-y: auto !important;
  // min-height: 400px !important;
  height: auto !important;
  .mat-dialog-content{
  margin: 0px !important;
  padding: 0 16px !important;
  }
  .mat-dialog-actions{
    margin-bottom: 0px !important;
  }
}
.mat-dialog-actions {
  &.mat-module-footer {
    border-top: 1px solid #e2e2ea;
  }
}

.filter {
  display: flex;
  position: relative;
  padding: 0px 0px 0px 0px;
  align-items: center;
  align-content: center;
  li {
    padding: 0px 10px;
    list-style: none;
    position: relative;
    display: flex;
    a {
      color: $color-primary;
      display: flex;
      align-items: center;
      font-size: $globle-text-second;

      &:hover,
      &:focus {
        color: #2b2b2b;
        text-decoration: none;
      }
    }

    .select-location {
      height: calc(1.2em + 0.75rem + 2px);
      border: none;
      color: #2b2b2b;
      font-weight: 500;
      padding-left: 1px;
      background-color: transparent;
      border: none;
      // &::placeholder{
      //     color:#ffc107;
      // }
    }
  }
  .filter-icon {
    padding-left: 31px;
    &::before {
      background-image: url(./assets/images/web-icons/filter.svg);
      background-position: 0px center;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0px;
      top: 0px;
      content: "";
    }
  }
}

//  Style of the table css
.table {
  &-outline {
    border-bottom: 1px solid #e2e2ea;
  }
  th,
  td {
    // border-color: #E2E2EA;
    font-size: 13px;
    padding: 0.4rem;
    vertical-align: middle;
  }
  th {
    padding: 0.58rem;
  }

  .thead-light th {
    background-color: #fafbff;
    border-color: #e2e2ea;
  }
}
.table-hover tbody tr:hover {
  background-color: #fafbff;
}
.profile-holder {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 8px;
  margin-left: 0px;
}
.profile-link {
  float: left;
}

.dataTables_length {
  label {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
  }
  select {
    width: auto;
    display: inline-block;
  }
}
.status {
  padding: 0em;
  font-weight: 600;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-active,
  &-isactive,
  &-pending {
    width: 100%;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    border-radius: 0px;
    font-weight: 600;
  }
  &-active {
    background-color: var(--active-color);
  }
  &-isactive {
    background-color: var(--inactive-color);
  }
  &-pending {
    background-color: var(--pending-color);
  }
  &-Order {
    background-color: var(--pending-color);
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.page-status {
  .pagenation-status {
    font-size: 12px;
  }
}
.pagination-showe-entries {
  width: 65px;
}
.material-icons {
  font-size: 22px;
}

.search-container {
  position: relative;
  display: inline-block;
  margin: 4px 2px;
  height: 38px;
  width: 50px;
  vertical-align: bottom;
}

.mglass {
  display: inline-block;
  pointer-events: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  color: $color-primary;
}

.searchbutton {
  position: absolute;
  font-size: 22px;
  width: 100%;
  margin: 0;
  padding: 0;
  top: 0px;
  left: 0px;
  z-index: 10;
  width: 38px;
}

.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;

  color: $color-primary;
}

.search {
  position: relative;
  left: 0px; /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
  border: transparent;
  padding-left: 38px;
  outline: none;
  width: 260px;
  height: 100%;
  z-index: 1;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  font-size: $globle-text-second;
  border-radius: 3px;
  -webkit-appearance: textfield;
}

.search:focus {
  width: 260px; /* Bar width+1px */
  padding: 0 16px 0 38px;
  border: 1px solid $color-secondary;
}

.expandright {
  left: auto;
  right: 49px; /* Button width-1px */
}

.expandright:focus {
  padding: 0 0 0 16px;
}

.active-color,
.inactive-color,
.pending-color,
.order-color {
  width: 12px;
  height: 12px;
  border-radius: 3px;
}
.active-color {
  background-color: var(--active-color);
}
.inactive-color {
  background-color: var(--inactive-color);
}
.pending-color {
  background-color: var(--pending-color);
}
.order-color {
  background-color: var(--order-color);
}

.manage-review-leftside {
  border-right: 1px solid #d5d5dc;
}

.manage-review-rightside{
  .breadcrumb-row{
    border-bottom: 1px solid #d5d5dc;
    background-color: #fff;
    min-height: 76px;
  }
}
.overall-ratting-bg {
  background: url(./assets/images/web-icons/ratting-star-bg.svg) no-repeat 0px
    0px;
  height: 135px;
  max-width: 100%;
  padding: 15px 18px 18px 18px;
  background-size: cover;
  width: auto;
  display: flex;
  align-items: flex-start;
  border-radius: 10px;
  margin-top: 15px;
  flex-direction: column;
}
.ratting {
  &-number {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 700;
  }
  &-star {
    .fa {
      &-star {
        color: #635863;
        font-size: 1.7rem;
        &.checked {
          color: #faa731;
        }
      }
    }
  }
  &-text {
    color: #fff;
    font-size: 0.87rem;
    font-weight: 400;
  }
}
.rating-breakdown {
  width: 100%;
  .ratingtext,
  .ratingtextT {
    font-size: 14px;
    color: #3c2e3c;
    font-weight: 600;
    height: 17px;
    i {
      color: #faa731;
      font-size: 14px;
      padding-left: 2px;
    }
  }
  .progress {
    margin: 3px 0;
    height: 22px;
    border-radius: 0;
    background: #e5e5e5;
    .progress-bar {
      flex-direction: column;
      justify-content: center;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #e5e5e5;
      transition: width 0.6s ease;
      &-success {
        background-color: #79caa1;
      }
      &-primary {
        background-color: #aeda8b;
      }
      &-info {
        background-color: #fcda54;
      }
      &-warning {
        background-color: #fcb251;
      }
      &-danger {
        background-color: #fa8e60;
      }
    }
  }
}


.tab-nav {
  display: flex;
  flex-direction: row;
  list-style: none;
  li {
    padding: 4px 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-right:8px;
    color: #635863;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    &.active {
      color: #3C2E3C;
      border-bottom: 2px solid  var(--orange-text-color);
    }
    &:last-child {
        margin-right: 0px;
    }
  }
}
.tab-contant-body {
//   border: 1px solid #bfc3ca;
  .locations-list {
    list-style: none;
        li {
            padding: 4px 10px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0.5px;
            color: #324251;
            border-bottom: 3px solid transparent;
            cursor: pointer;
        &.active {
          color: #329E4A;
          border-bottom: 3px solid #329E4A;
        }
        // &:first-child {
        //   padding-left: 10px;
        // }
     }
   }
  .tab-contant-body{
    border: 1px solid #BFC3CA;
    padding-bottom: 0.6rem;
    .locations-list {
      list-style: none;
      padding-top: 0.4rem;
      li {
        padding: 3px 10px;
        font-style: normal;
        letter-spacing: 0.5px;
        color: #324251;
      }
    }
  }
  .tab-products {
    width: 100%;
    padding: 0.5rem;
    table {
      border: 1px solid #d7d7d7;
    }
    table {
      &.table-whiteboard {
        tr {
          th {
            background-color: #f1f1f5;
            color: #324251;
            font-weight: 600;
          }
        }
      }
    }
  }
  .tab-Teams {
    width: 100%;
    padding: 0.8rem;
  }

  .progress-2 {
    width: 3.5em;
    flex-basis: 0 0 3.5em;
  }
  .label {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.9em;
  }
}

.account-detail{
  height: 100%;

  &-folder{
      &.book{
          width:75%;
          position: relative;
          .document-img{
              height: auto;
          }
          &.details{
             
              .hedding{
                  color: #3C2E3C;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 1rem;
                  font-weight: 600;
              }
              .text{
                  color: rgb(99 88 99 / 80%);
                  font-size:0.84rem;
                  font-weight: 600;
              }
          }  
      }  
  }
}

.ratting {
&-number {
  color: rgb(99 88 99 / 80%);
  font-size: 0.86rem;
  font-weight: 600;
}
&-star {
  line-height: 19px;
  .fa {
    &-star {
      color: #635863;
      font-size: 1rem;
      &.checked {
        color: #FAA731;
      }
    }
  }
}
&-text {
  color: #3C2E3C;
  font-size: 0.87rem;
  font-weight: 400;
}
}
.date-section{
.date-text{
  color: rgb(99 88 99 / 80%);
  font-size:0.84rem;
  font-weight: 600;
  span{
    color: var(--orange-text-color);
  }
}
}

.review-details{
border: 1px solid #ffffff00;
cursor:default;
&:hover{
  background-color:  rgba(244, 114, 64, 0.03);
  border: 1px solid  var(--orange-text-color);
  border-radius: 2px;
  cursor:default;
}

}
.profile{
  border-radius: 50%;
  width: 72px;
  height: 72px;
  position: relative;
  &-active{
      position:absolute;
      top:2px;
      right:10px;
      width: 10px;
      height:10px;
      background-color: #329E4A;
      border-radius:10px;
  }
}

.body-content{
  min-height: 100vh;
}
.mat-form-field.error-form-field{
  .mat-form-field-wrapper{
      padding-bottom: 5px;
  }
}

// Services page button CSS

.continue-button{
  width:100%;
  min-height: 48px;
  .mat-button-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    .count-chekout-items{
      background: #635863;
     border: 1px solid #8C808C;
     border-radius: 4px;
     width: 32px;
     height: 32px;
     display: flex;
    justify-content: center;
    align-items: center;
    }

    .button-text{
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      .mat-icon{
        height: 22px;
        width: 15px;
      }
    }
}
}
// End Services page button CSS
.banner-bg{    
  padding-top: 70px;   
}
.content-bg{
  padding: 0px;
  ul{
          padding-left:20px;
      }
}
// Stepper CSS Starts

.stepper{
  .mat-step{
    margin-bottom: 10px;
  .mat-step-header{
      padding:10px 16px;
      background: $color-light-grey;
      border-bottom:1px solid $color-grey;
      height: 42px;    
      .mat-step-icon{
        height: 38px;
        width: 38px;
        &.mat-step-icon-selected{
          background-color: #64c880;
        }
      }
      .mat-step-label{
        min-width: 97%;
        width:100%;
        max-width: 97%;
      }
      
  }
  .mat-stepper-vertical, .mat-vertical-content-container{
    margin-left: 0px;
    &.mat-stepper-vertical-line::before{
      display: none;
    }
    .mat-vertical-content{
       padding:5px 0px;
    }
  }
}
}

.mat-button.mat-button-disabled.mat-button-disabled{
  color: rgb(255 255 255 / 66%) !important;
}


.vendor-details{
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.setup-profile-container{
  padding: 1rem 0 0 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  .setup-tab{
      width:100%;
      border-bottom: 1px solid #c2c4c5;
  }
  .setup-nav {
      display: flex;
      flex-direction: row;
      list-style: none;
      width: 100%;
      li {
          padding: 10px 8px;
          font-style: normal;
          font-weight: 700;
          cursor: pointer;
          color:#3C2E3C;
          min-height: 50px;
          margin-right: 16px;         
        &.active {
          color: #F47240;
          border-bottom: 3px solid #F47240;
        }
        
      }
    }
   
}

.setup-contant-body{
  padding: 10px 20px;
  // border: 1px solid #dde6e9;
  width: 100%;
 
    .plan-content{
        padding:0;
        .title-text {
          h2 {
            font-size: 1.3rem;
            margin-bottom: 17.5px;
            font-weight:700;
          }
          p {
            font-size: 10.5pt;
          }
          .items-count{
              font-weight: 600;
              color:#F47240;
              font-size: 0.89rem;
          }
          &.card-items-details{  
            .items-row{ 
              border-bottom: 1px dashed #d5d5dc;
              padding:10px 0px 10px 0px;             
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;         
              .items-name{
                  font-size: 0.9rem;
                  font-weight: 600;
              }
            }
          }
        }
    }
    &.facility-tab{
      width:65em;
    } 
}

.categories-list{
  list-style: none;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  li{
      padding: 0px 0px 1px;
      width: 100%;
      display: flex;
      a{
          font-style: normal;
          font-weight: 500;
          color:#3C2E3C; 
          font-size: 14px;
          text-decoration: none;
          border-left: 3px solid transparent;
          width: 100%;
          padding: 8px 10px 8px 5px;
          cursor: pointer;
          &:hover, &:focus{
              color:#F47240; 
              font-weight: 500;
              text-decoration: none;
              border-left: 3px solid #F47240;
              background-color:  rgba(244, 114, 64, 0.15);
          }
          &.current{
              color:#F47240; 
              text-decoration: none;
              font-weight: 500;
              border-left: 3px solid #F47240;
              background-color:  rgba(244, 114, 64, 0.15);
          }
      }
  }
}
// Chart legend CSS 
.legend{
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;

   li{
     padding: 0px 10px;
     display: flex;
     align-items: center;
     font-size: 0.89rem;
     &.legend-order, &.legend-income{
      span{
        width:12px;
        height: 12px;
        margin-right: 6px;
        background: #00bcd8;        
      }      
     }
     &.legend-income{
      span{
        background: #F47240;  
      }
     }
   }
}

.mat-checkbox-layout{
  font-weight: 700;
  font-size: 1rem;
}
button:focus{
  outline: none;
  text-decoration: none;
}
.close{
      color: #3c2e3c;
      text-decoration: none;
      outline: none;
  .close:hover {
      color: #3c2e3c;
      text-decoration: none;
      outline: none;
  }
}

label.star{
  padding: 12px !important;
  font-size: 18px !important;
  color: #3c2e3c !important;
}


.ngx-pagination li{
  a, button{
    font-size: 0.82rem;
    color: #3c2e3c !important;
  }
}
.ngx-pagination .disabled{
  font-size: 0.82rem;
}