/* Loader css start here */
.loader_wrapper {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
}

.loader {
  border: 10px solid #e2e2e2; /* Light grey */
  border-top: 10px solid #ffd540; /* Blue */
  border-radius: 50%;
  animation: spin 2s linear infinite;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 100000;
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loader css end here */
